import React from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import EnableKycForm from "./EnableKycForm";

const Config = () => {
  return (
    <DefaultLayout>
      <div>
        <EnableKycForm />
      </div>
    </DefaultLayout>
  );
};

export default Config;
