import { apiRoute } from "../utils";
import { BaseRequest } from "./Request";

export const getFlag = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/flags`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const updatKycStatus = async (data: any, id: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/flags/${id}`);
  const response = (await baseRequest.patch(url, data)) as any;
  return await response?.json();
};
