import React, { useState, useEffect } from "react";
import ConfirmDialog from "../../components/Base/ConfirmDialog";
import { getFlag, updatKycStatus } from "../../request/config";
import { alertSuccess } from "../../store/actions/alert";
import { useDispatch } from "react-redux";
import { FormControl, Switch } from "@material-ui/core";

const EnableKycForm = () => {
  const dispatch = useDispatch();
  const [kycFeatFlag, setKycFeatFlag] = useState(false);
  const [kycFlag, setKycFlag] = useState<any>("");
  const [isOpenEditPopupKyc, setIsOpenEditPopupKyc] = useState(false);
  const [displayKycLabel, setDisplayKycLabel] = useState(true);
  const [localKycFlag, setLocalKycFlag] = useState(true);

  useEffect(() => {
    fetchFlags();
  }, []);

  const fetchFlags = async () => {
    try {
      const response = await getFlag();
      const flags = response.data;
      const currentROIFlag = flags?.find(
        (flag: any) => flag?.name === "update_kyc_status_on_chaingpt_pad"
      );

      if (response?.status === 200) {
        setKycFlag(currentROIFlag);
        setKycFeatFlag(currentROIFlag.value);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMaxCapSwitchChange = (event: any) => {
    const { checked } = event?.target;
    setLocalKycFlag(checked);
    setIsOpenEditPopupKyc(true);
    setDisplayKycLabel(checked);
  };

  const handleSubmitPopup = async () => {
    const name = kycFlag?.name;
    try {
      const response = await updatKycStatus(
        { name, value: localKycFlag },
        kycFlag?.id
      );
      fetchFlags();
      dispatch(alertSuccess("Successfull"));
      setIsOpenEditPopupKyc(false);
      setKycFeatFlag(response.data.value);
    } catch (e) {
      console.log(e, "error");
    }
  };

  const handleCancel = () => {
    setIsOpenEditPopupKyc(false);
    setLocalKycFlag(kycFeatFlag);
  };

  return (
    <>
      <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
        <Switch onChange={handleMaxCapSwitchChange} checked={kycFeatFlag} />
        <h4 style={{ marginRight: "10px", marginTop: "5px", fontSize: "14px" }}>
          Enable Kyc
        </h4>
      </FormControl>
      <ConfirmDialog
        title={
          displayKycLabel ? "Enable KYC" : "Disable KYC"
        }
        open={isOpenEditPopupKyc}
        confirmLoading={false}
        onConfirm={handleSubmitPopup}
        onCancel={handleCancel}
      >
        <div>
          Are you sure you want to {displayKycLabel ? "enable" : "disable"}{" "}
          Kyc?
        </div>
      </ConfirmDialog>
    </>
  );
};

export default EnableKycForm;
