import React from "react";

import useStyles from "../style";
import { renderError } from "../../../utils/validate";
import { isValidTonAddress } from "../../../utils";

function TonInputBox(props: any) {
  const classes: any = useStyles();
  const {
    register,
    setValue,
    errors,
    isEditDisabled,
    poolDetail
  } = props;

  const handleTokenGetInfo = (e: any) => {
    console.log(e.target);
    setValue(e.target.value);
  };
  return (
    <>
        <div>
          <label className={classes.formControlLabel}>Ton Address</label>
          <div className={classes.formControlInputLoading}>
            <input
              type="text"
              name="custom_token"
              ref={register({
                validate: {
                  invalidToken: (value : string) => isValidTonAddress(value) || "Invalid TON address.",
                },
              })}
              maxLength={255}
              defaultValue={poolDetail?.custom_token}
              onChange={handleTokenGetInfo}
              className={classes.formControlInput}
              style={{
                backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
              }}
            />
          </div>
          <p className={`${classes.formErrorMessage}`}>
            {renderError(errors, "custom_token")}
          </p>
        </div>
    </>
  );
}

export default TonInputBox;
